import React from 'react'
import UpcomingReviewCycleAutomations from './views/ReviewAutomations/UpcomingReviewCycleAutomations'
const GeneralSettings = React.lazy(() => import('./views/Settings/GeneralSettings'))
const Permissions = React.lazy(() => import('./views/Permissions'))
const Support = React.lazy(() => import('./views/Support'))
const Licenses = React.lazy(() => import('./views/Licenses'))
const Security = React.lazy(() => import('./views/Security'))
const FeedbackQuestions = React.lazy(() => import('./views/FeedbackQuestions'))
const FeedbackTemplates = React.lazy(() => import('./views/FeedbackTemplates'))
const PulseQuestions = React.lazy(() => import('./views/PulseQuestions'))
const PulseTemplates = React.lazy(() => import('./views/PulseTemplates'))
const ReviewTemplates = React.lazy(() => import('./views/ReviewTemplates'))
const ReviewAutomations = React.lazy(() => import('./views/ReviewAutomations/ReviewAutomations'))
const CreateReviewTemplatesHOC = React.lazy(() => import('./views/ReviewTemplates/CreateReviewTemplate'))
const Create360DegreeFeedback = React.lazy(() => import('./views/feedback360Degree/Create360DegreeFeedback'))
const ReviewCycles = React.lazy(() => import('./views/ReviewCycles'))
const Badges = React.lazy(() => import('./views/Badges'))
const TalkingPoints = React.lazy(() => import('./views/TalkingPoints'))
const MainReports = React.lazy(() => import('./views/Reports/MainReports'))
const PartnerReports = React.lazy(() => import('./views/Reports/PartnerReports'))
const CoreUIIcons = React.lazy(() => import('./views/Icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/Icons/Flags'))
const FontAwesome = React.lazy(() => import('./views/Icons/FontAwesome'))
const SimpleLineIcons = React.lazy(() => import('./views/Icons/SimpleLineIcons'))
const Alerts = React.lazy(() => import('./views/Notifications/Alerts'))
const FocusPeople = React.lazy(() => import('./views/FocusPeople'))
const FocusPeopleTypes = React.lazy(() => import('./views/FocusPeopleTypes'))
const TipsAndResources = React.lazy(() => import('./views/TipsAndResources'))
const CreateTaskGroup = React.lazy(() => import('./views/TaskAutomations/CreateTaskGroup'))
const CreateAutomation = React.lazy(() => import('./views/TaskAutomations/CreateAutomation'))
const TaskGroups = React.lazy(() => import('./views/TaskAutomations/TaskGroups'))
const ManageGoalTypes = React.lazy(() => import('./views/Goals/ManageGoalTypes'))
const ManageRewards = React.lazy(() => import('./views/Rewards/ManageRewards/ManageRewards'))
const RewardTable = React.lazy(() => import('./views/Rewards/RewardTable/RewardTable'))
const Users = React.lazy(() => import('./views/Users/Users'))

const CompanyResources = React.lazy(() => import('./views/CompanyResources'))
const MyOrganizationResources = React.lazy(() => import('./views/CompanyResources/MyOrganizationResources'))
const APIKeys = React.lazy(() => import('./views/APIKeys'))
const Logs = React.lazy(() => import('./views/Logs'))
const ReviewCycle = React.lazy(() => import('./views/ReviewCycles/ReviewCycle'))
const AutomateReviewCycles = React.lazy(() => import('./views/ReviewCycles/AutomateReviewCycles'))

const GettingStarted = React.lazy(() => import('./views/GettingStarted/GettingStarted'))
const MeetingSettingsModule = React.lazy(() => import('./views/Modules/MeetingSettings'))
const FeedbackSettingsModule = React.lazy(() => import('./views/Modules/FeedbackSettings'))
const GoalSettingsModule = React.lazy(() => import('./views/Modules/GoalSettings'))
const NoteSettingsModule = React.lazy(() => import('./views/Modules/NoteSettings'))
const RecognitionSettingsModule = React.lazy(() => import('./views/Modules/RecognitionSettings'))
const ReviewSettingsModule = React.lazy(() => import('./views/Modules/ReviewSettings'))
const SurveySettingsModule = React.lazy(() => import('./views/Modules/SurveySettingsModule'))
const TaskSettingsModule = React.lazy(() => import('./views/Modules/TaskSettings'))
const BlockedUsers = React.lazy(() => import('./views/Users/BlockedUsers'))
const HomePage = React.lazy(() => import('./views/Home/HomePage'))
const UserAttributes = React.lazy(() => import('./views/UserAttributes/UserAttributes'))
const Feedback360DegreeCycles = React.lazy(() => import('./views/feedback360Degree/Feedback360DegreeCycles'))
const Feedback360DegreeCycle = React.lazy(() => import('./views/feedback360Degree/Feedback360DegreeCycle'))

const Feedback360DegreeTemplates = React.lazy(() =>
  import('./views/Feedback360DegreeTemplates/Feedback360DegreeTemplates')
)

const AutomateFeedback360DegreeCycles = React.lazy(() =>
  import('./views/feedback360Degree/AutomateFeedback360DegreeCycles')
)

const CreateFeedback360DegreeTemplatesHOC = React.lazy(() =>
  import('./views/Feedback360DegreeTemplates/CreateFeedback360DegreeTemplate')
)

const rescrictedTenantsPage = React.lazy(() => import('./views/Tenants/TenantsPage'))

const Roles = React.lazy(() => import('./views/Career/Roles/Roles'))

const CompetencyLibrary = React.lazy(() => import('./views/Career/CompetencyLibrary/CompetencyLibrary'))

const IndividualDevelopmentPlanPage = React.lazy(() =>
  import('./views/Career/IndividualDevelopmentPlan/IndividualDevelopmentPlanSettings')
)
const IndividualDevelopmentPlansPage = React.lazy(() =>
  import('./views/Career/IndividualDevelopmentPlan/IndividualDevelopmentPlans')
)
const SuccessionPlanSettings = React.lazy(() => import('./views/Career/SuccessionPlan/SuccessionPlanSettings'))

const LearningMaterials = React.lazy(() => import('./views/Career/LearningMaterials/LearningMaterials'))

const Feedback360DegreeAutomationItemList = React.lazy(() =>
  import('./views/Feedback360DegreeTemplates/Feedback360DegreeAutomationItemList')
)
const NotificationSettings = React.lazy(() => import('./views/Settings/notification-settings/NotificationSettings'))
const MyOrganizationPage = React.lazy(() => import('./views/Settings/MyOrganizationPage'))
const AnniversaryRecognitionAutomations = React.lazy(() =>
  import('./views/Settings/reco-settings/AnniversaryRecognitionAutomations')
)

const CheckinTemplates = React.lazy(() => import('./views/CheckinTemplates/CheckinTemplates'))
const CustomBlocksHomePage = React.lazy(() => import('./views/CustomBlocks/CustomBlocksHomePage/CustomBlocksHomePage'))
const ReferralProgramPage = React.lazy(() => import('./views/ReferralProgram/ReferralProgramPage'))
const InternalJobBoardPage = React.lazy(() => import('./views/Career/InternalJobBoard/InternalJobBoardPage'))
const ApplicationsPage = React.lazy(() => import('./views/Career/InternalJobBoard/Applications/ApplicationsPage'))
const NewsFeedPage = React.lazy(() => import('./views/NewsFeed/NewsFeedPage'))
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/home', exact: true, name: 'Home', component: HomePage },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
  { path: '/notifications', exact: true, name: 'Notifications', component: Alerts },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  //{ path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/reports', exact: true, name: 'Reports', component: MainReports },
  /*{ path: '/reports/main', name: 'Reports', component: MainReports },
  { path: '/reports/recognitions', name: 'RecognitionReports', component: RecognitionReports },
  { path: '/reports/feedbacks', name: 'FeedbackReports', component: FeedbackReports },*/
  { path: '/settings/generalsettings', name: 'General Settings', component: GeneralSettings },
  { path: '/settings/notificationsettings', name: 'Notification Settings', component: NotificationSettings },
  { path: '/settings/myorganization', name: 'My Organization Page', component: MyOrganizationPage },
  { path: '/meetingsettings', exact: true, name: 'Meetings', component: MeetingSettingsModule },
  { path: '/meetingsettings/resources', exact: true, name: 'Resources', component: CompanyResources },
  { path: '/goalsettings', exact: true, name: 'Goal', component: GoalSettingsModule },
  { path: '/goalsettings/resources', exact: true, name: 'Resources', component: CompanyResources },
  { path: '/goalsettings/managegoaltypes', exact: true, name: 'Manage Goal Types', component: ManageGoalTypes },
  { path: '/reviewsettings', exact: true, name: 'Reviews', component: ReviewSettingsModule },
  { path: '/reviewsettings/resources', exact: true, name: 'Resources', component: CompanyResources },
  { path: '/surveysettings', exact: true, name: 'Surveys', component: SurveySettingsModule },
  { path: '/surveysettings/resources', exact: true, name: 'Resources', component: CompanyResources },
  { path: '/recognitionsettings', exact: true, name: 'Recognitions', component: RecognitionSettingsModule },
  {
    path: '/recognitionsettings/resources',
    exact: true,
    name: 'Resources',
    component: CompanyResources
  },
  { path: '/feedbacksettings', exact: true, name: 'Feedback', component: FeedbackSettingsModule },
  { path: '/feedbacksettings/resources', exact: true, name: 'Resources', component: CompanyResources },
  { path: '/tasksettings', exact: true, name: 'Task', component: TaskSettingsModule },
  { path: '/tasksettings/resources', exact: true, name: 'Resources', component: CompanyResources },
  { path: '/tasksettings/taskgroups', exact: true, name: 'Task Groups', component: TaskGroups },
  {
    path: '/tasksettings/taskgroups/createtaskgroup',
    exact: true,
    name: 'Create Task Group',
    component: CreateTaskGroup
  },
  {
    path: '/tasksettings/taskgroups/createautomation/:id',
    exact: true,
    name: 'Create Automation',
    component: CreateAutomation
  },
  { path: '/notesettings', name: 'Note', component: NoteSettingsModule },
  { path: '/gettingstarted', name: 'Getting Started', component: GettingStarted },
  { path: '/permissions', name: 'Permissions', component: Permissions },
  { path: '/support', name: 'Support', component: Support },
  { path: '/licenses', name: 'Licenses', component: Licenses },
  { path: '/security', name: 'Security', component: Security },
  { path: '/partnerpage', name: 'Partner dashboard', component: PartnerReports },
  { path: '/logs', name: 'Logs', component: Logs },
  { path: '/reviewsettings/reviewtemplates', exact: true, name: 'Review Templates', component: ReviewTemplates },
  { path: '/reviewsettings/reviewautomations', exact: true, name: 'Automation Rules', component: ReviewAutomations },
  { path: '/surveysettings/surveytemplates', exact: true, name: 'Survey Templates', component: ReviewTemplates },
  {
    path: '/feedbacksettings/360degreefeedbacktemplates',
    exact: true,
    name: '360-Degree Feedback Templates',
    component: Feedback360DegreeTemplates
  },
  {
    path: '/reviewsettings/reviewtemplates/createreviewtemplate',
    name: 'Create review template',
    component: CreateReviewTemplatesHOC
  },
  {
    path: '/surveysettings/surveytemplates/createsurveytemplate',
    name: 'Create survey template',
    component: CreateReviewTemplatesHOC
  },
  {
    path: '/feedbacksettings/360degreefeedbacktemplates/create360degreefedbacktemplate',
    name: 'Create 360-degree feedback template',
    component: CreateFeedback360DegreeTemplatesHOC
  },
  {
    path: '/feedbacksettings/create360degreefeedbackcycle',
    name: 'Create 360-degree feedback cycle',
    component: Create360DegreeFeedback
  },
  {
    path: '/feedbacksettings/360degreefeedbackcycles/:id',
    name: '360-degree feedback cycle',
    component: Feedback360DegreeCycle
  },
  {
    path: '/feedbacksettings/360degreefeedbackcycles',
    name: '360-Degree Feedback Cycles',
    component: Feedback360DegreeCycles
  },
  {
    path: '/tenants',
    name: 'Tenants',
    component: rescrictedTenantsPage
  },

  {
    path: '/reviewsettings/reviewtemplates/:id',
    exact: true,
    name: 'Update or start review template',
    component: CreateReviewTemplatesHOC
  },
  {
    path: '/surveysettings/surveytemplates/:id',
    exact: true,
    name: 'Update or start survey template',
    component: CreateReviewTemplatesHOC
  },
  {
    path: '/feedbacksettings/360degreefeedbacktemplates/:id',
    exact: true,
    name: 'Update or start 360 degree feedback template',
    component: CreateFeedback360DegreeTemplatesHOC
  },
  { path: '/reviewsettings/reviewcycles', exact: true, name: 'Review Cycles', component: ReviewCycles },
  { path: '/surveysettings/surveycycles', exact: true, name: 'Survey Cycles', component: ReviewCycles },
  { path: '/reviewsettings/reviewcycles/reviewcycle/:id', exact: true, name: 'Review Cycle', component: ReviewCycle },
  { path: '/surveysettings/surveycycles/surveycycle/:id', exact: true, name: 'Survey Cycle', component: ReviewCycle },
  { path: '/reviewsettings/reviewautomation/:id', exact: true, name: 'Review Automation', component: ReviewCycle },
  {
    path: '/feedbacksettings/360degreefeedbackautomation/:id',
    exact: true,
    name: '360-Degree Feedback Automation',
    component: Feedback360DegreeAutomationItemList
  },
  { path: '/surveysettings/surveyautomation/:id', exact: true, name: 'Survey Automation', component: ReviewCycle },
  {
    path: '/reviewsettings/automatereviewcycles',
    exact: true,
    name: 'Review Automations',
    component: AutomateReviewCycles
  },
  {
    path: '/reviewsettings/upcomingreviewcycleautomations',
    exact: true,
    name: 'Upcoming Review Cycle Automations',
    component: UpcomingReviewCycleAutomations
  },
  {
    path: '/surveysettings/automatesurveycycles',
    exact: true,
    name: 'Survey Automations',
    component: AutomateReviewCycles
  },
  {
    path: '/feedbacksettings/automate360degreefeedbackcycles',
    exact: true,
    name: '360-Degree Feedback Automations',
    component: AutomateFeedback360DegreeCycles
  },
  {
    path: '/reviewsettings/reviewquestionsets',
    exact: true,
    name: 'Review Question Sets',
    component: FeedbackTemplates
  },
  {
    path: '/surveysettings/surveyquestionsets',
    exact: true,
    name: 'Survey Question Sets',
    component: FeedbackTemplates
  },
  {
    path: '/reviewsettings/reviewquestionsets/:id',
    exact: true,
    name: 'Edit Question Set',
    component: FeedbackQuestions
  },
  {
    path: '/reviewsettings/reviewquestionsets/:id',
    exact: true,
    name: 'Edit Question Set',
    component: FeedbackQuestions
  },
  { path: '/pulsetemplates', exact: true, name: 'Pulse Templates', component: PulseTemplates },
  { path: '/pulsequestions', name: 'Pulse Questions', component: PulseQuestions },
  { path: '/pulsetemplates/:id', name: 'Edit Questions', component: PulseQuestions },
  {
    path: '/feedbacksettings/feedbacktemplates',
    exact: true,
    name: 'Feedback Templates',
    component: FeedbackTemplates
  },
  { path: '/feedbackquestions', name: 'Feedback Questions', component: FeedbackQuestions },
  { path: '/feedbacksettings/feedbacktemplates/:id', name: 'Edit Questions', component: FeedbackQuestions },
  { path: '/recognitionsettings/badges', name: 'Badges', component: Badges },
  { path: '/meetingsettings/talkingpoints', name: 'Talking Points', component: TalkingPoints },
  { path: '/meetingsettings/checkintemplates', name: 'Check-in Templates', component: CheckinTemplates },
  { path: '/users/activeusers', exact: true, name: 'Users', component: Users },
  { path: '/users/blockedlist', exact: true, name: 'Blocked Users List', component: BlockedUsers },
  { path: '/users/userattributes', exact: true, name: 'User Attributes', component: UserAttributes },
  { path: '/focuspeople', name: 'Focus People', component: FocusPeople },
  { path: '/focustypes', name: 'Focus People Types', component: FocusPeopleTypes },
  { path: '/meetingsettings/tipsandresources', name: 'Tips And Resources', component: TipsAndResources },
  { path: '/apikeys', name: 'API Keys', component: APIKeys },
  { path: '/career/roles', exact: true, name: 'Roles', component: Roles },
  { path: '/career/competencylibrary', exact: true, name: 'Competency Library', component: CompetencyLibrary },
  {
    path: '/myorganization/internaljobboard',
    exact: true,
    name: 'Internal job board',
    component: InternalJobBoardPage
  },
  {
    path: '/myorganization/internaljobboard/:id/appliedusers',
    exact: true,
    name: 'Applied Users',
    component: ApplicationsPage
  },
  {
    path: '/career/individualDevelopmentPlan',
    exact: true,
    name: 'Individual Development Plan',
    component: IndividualDevelopmentPlanPage
  },
  {
    path: '/career/individualDevelopmentPlans',
    exact: true,
    name: 'Individual Development Plans',
    component: IndividualDevelopmentPlansPage
  },
  ...(process.env.REACT_APP_ENVIRONMENT !== 'prod'
    ? [{ path: '/career/learningmaterials', exact: true, name: 'Learning Materials', component: LearningMaterials }]
    : []),
  { path: '/recognitionsettings/managerewards', exact: true, name: 'Manage Rewards', component: ManageRewards },
  { path: '/recognitionsettings/rewardtable', exact: true, name: 'Reward Table', component: RewardTable },
  {
    path: '/recognitionsettings/anniversaryrecognitionautomation',
    exact: true,
    name: 'Anniversary recognition automation',
    component: AnniversaryRecognitionAutomations
  },
  { path: '/customblocks', name: 'Custom blocks', component: CustomBlocksHomePage },
  { path: '/referralprogram', name: 'Referral program', component: ReferralProgramPage },
  { path: '/career/successionplan', exact: true, name: 'Succession Plan', component: SuccessionPlanSettings },
  { path: '/newsfeed', name: 'News Feed', component: NewsFeedPage },
  { path: '/myorganizationresources', name: 'My Organization Resources', component: MyOrganizationResources }
]

export default routes
