const USER_ROLE = {
  User: 0,
  ReportReader: 1,
  GlobalAdministrator: 2,
  ConfigurationEditor: 3,
  ScopedAdministrator: 4
}

const USER_ROLE_DROPDOWN_FILTER_OPTIONS = [
  { label: 'Global Administrator', value: USER_ROLE.GlobalAdministrator },
  { label: 'Scoped Administrator', value: USER_ROLE.ScopedAdministrator },
  { label: 'User', value: USER_ROLE.User },
  { label: 'Report Reader', value: USER_ROLE.ReportReader },
  { label: 'Configuration Editor', value: USER_ROLE.ConfigurationEditor }
]

const EMPLOYMENT_TYPE = {
  FULL_TIME: 0,
  PART_TIME: 1,
  INTERNSHIP: 2,
  CONTRACT: 3,
  FREELANCE: 4,
  VOLUNTEER: 5
}

const COMPETENCY_LEVEL = {
  ENTRY: 0,
  INTERMEDIATE: 1,
  PROFFESSIONAL: 2,
  LEADERSHIP: 3,
  C: 4
}

export { USER_ROLE, EMPLOYMENT_TYPE, COMPETENCY_LEVEL, USER_ROLE_DROPDOWN_FILTER_OPTIONS }
